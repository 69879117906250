import { EnvironmentUrls } from 'Roblox';

const { websiteUrl } = EnvironmentUrls;

export default {
  title: 'Heading.PlayerAssetsBadges',
  seeAllLabel: 'Action.SeeAll',
  limitOfBadgesToDisplay: 6,
  getInventoryUrlForBadge(userId) {
    return `${websiteUrl}/users/${userId}/inventory/#!/badges`;
  }
};
