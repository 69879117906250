import { authenticatedUser } from 'header-scripts';

const getProfileUserId = () => {
  const currentUrlPath = window?.location?.pathname;
  const urlParams = currentUrlPath ? currentUrlPath.split('/') : [];
  const profileUserId =
    urlParams?.length && urlParams.length > 3 && !Number.isNaN(urlParams[2])
      ? urlParams[2]
      : authenticatedUser?.id;
  return profileUserId;
};

export default {
  getProfileUserId
};
